<template>
  <div class="miservers">
    <panes>
      <template v-slot:left>
        <div class="miserver-list">
          <h1>
            MagicINFO Player Content
            <base-button
              v-on:click="$router.push({ name: 'magicinfo-player-content-new' })"
              class="right"
              isSmall
              type="THEMED"
              iconType="PLUS"
              title="Create Content Group"
            />
          </h1>
          <mi-player-content-table v-on:rowClick="handleRowClick" />
        </div>
      </template>
      <template v-slot:right>
        <div class="miserver-view">
          <MiPlayerContentRead
            v-if="state === 'view'"
            :playerContent="selected"
          />
          <MiPlayerContentCreate
            v-if="state === 'new'"
            :playerContent="selected"
          />
        </div>
      </template>
    </panes>
  </div>
</template>

<script>
import MiPlayerContentTable from '@/components/Table/MiPlayerContent.vue';
import MiPlayerContentRead from '@/components/MagicPlayer/Read.vue';
import MiPlayerContentCreate from '@/components/MagicPlayer/NewGroup.vue';
import Panes from '@/components/Panes/Panes.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';

export default {
  name: 'MiPlayerContent',
  components: {
    MiPlayerContentTable,
    MiPlayerContentRead,
    MiPlayerContentCreate,
    BaseButton,
    Panes,
  },
  data() {
    return {
      state: '',
      selected: '',
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'magicinfo-player-content-view' && to.params.groupUnique) {
        this.selected = to.params.groupUnique;
        this.state = 'view';
      } else if (to.name === 'magicinfo-player-content-new') {
        this.state = 'new';
      }
    },
  },
  methods: {
    handleRowClick(id) {
      this.selected = id;
    },
  },
  mounted() {
    if (typeof this.$route.params.groupUnique !== 'undefined') {
      this.selected = this.$route.params.groupUnique;
      this.state = 'view';
    } else if (this.$route.name === 'magicinfo-player-content-new') {
      this.state = 'new';
    }
  },
};
</script>

<style lang="scss" scoped>
.miservers {
  height: 100%;
}
.miserver-list {
  height: 100%;
}

.miserver-view {
  height: 100%;
  overflow-x: auto;
}
</style>
