<template>
  <div class="miserver-viewer">
    <h1 class="title">
      New Content Group
    </h1>
    <grid>
      <grid-col size="10">
        <card>
          <property title="Group Name">
            <input
              type="text"
              placeholder="Group Name"
              class="input-text"
              v-model="contentGroup"
            />
          </property>
          <base-button
            iconType="PLUS"
            v-on:click="createContentGroup"
            title="Create"
          />
        </card>
      </grid-col>
    </grid>
  </div>
</template>

<script>
import BaseComponent from '../Base.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import Property from '../Util/Property.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'new-miplayer-content',
  extends: BaseComponent,
  components: {
    Card,
    Grid,
    GridCol,
    Property,
    BaseButton,
  },
  data() {
    return {
      contentGroup: '',
    };
  },
  methods: {
    async createContentGroup() {
      if (!this.contentGroup) {
        return;
      }

      try {
        const response = await this.$utils.http().post('/api/v1/miplayer-content/groups', {
          groupUnique: this.contentGroup,
        });
        this.$root.$emit('reloadTable');
        this.$router.push(`/magicinfo-player-content/view/${response.data.unique}`);
      } catch (err) {
        this.$noty.warning(`Could not create content group: ${err.response.data.message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
