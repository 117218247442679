<template>
  <div class="miserver-table">
    <!-- Table for MagicINFO Player Content -->
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Latest Version</th>
          <th>N/O Versions</th>
        </tr>
      </thead>
      <tbody v-if="ajaxCompleted">
        <tr
          v-for="contentGroup in contentGroups"
          @click="handleRowClick(contentGroup.unique)"
          :data-id="contentGroup.id"
          :key="contentGroup.id"
        >
          <td>
            {{ contentGroup.unique }}
          </td>
          <td>
            {{ contentGroup.latestVersion }}
          </td>
          <td>
            {{ contentGroup.versions }} version(s)
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="loader">
            <img :src="loadingImage" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'miserverTable',
  data() {
    return {
      ajaxCompleted: false,
      contentGroups: [],
    };
  },
  methods: {
    async getContentGroups() {
      try {
        const response = await this.$utils.http().get('/api/v1/miplayer-content/groups');
        this.ajaxCompleted = true;
        this.contentGroups = response.data;
      } catch (err) {
        this.ajaxCompleted = true;
        this.$noty.warning(`Could not load the player content: ${err.response.data.message}`);
      }
    },
    async handleRowClick(id) {
      this.$emit('rowClick', id);
      this.$router.replace(`/magicinfo-player-content/view/${id}`);
    },
  },
  mounted() {
    this.getContentGroups();
    this.$root.$on('reloadTable', () => {
      this.getContentGroups();
    });
  },
};
</script>

<style lang="scss" scoped>
.miserver-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.miserver-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}
</style>
