<template>
  <div class="miserver-viewer" v-if="playerContent !== null">
    <h1 class="title">
      Content Group: {{ playerContent }}
    </h1>
    <grid>
      <grid-col size="10" v-if="contentVersions">
        <card title="Info">
          <TextProperty v-if="latestPlayerContent !== null"
          title="Latest Version"
          :value="latestPlayerContent.version" />
          <TextProperty title="Version(s)" :value="contentVersions.length.toString()" />
        </card>
        <card title="Content Version(s)">
          <table class="content-version-table">
            <thead>
              <tr>
                <th>Version</th>
                <th>Status</th>
                <th>Uploaded At</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contentVersion in contentVersions" :key="contentVersion.id">
                <td>{{ contentVersion.version ? contentVersion.version : '-' }}</td>
                <td>
                  <span v-if="contentVersion.deploymentStatus === 'deployed'">
                    <i class="uil uil-check-circle green" /> {{ contentVersion.deploymentStatus }}
                  </span>
                  <span v-else>
                    <i class="uil uil-hourglass blue"></i> {{ contentVersion.deploymentStatus }}...
                  </span>
                </td>
                <td>{{ $date(new Date(contentVersion.uploadedAt)).format('LLL') }}</td>
              </tr>
            </tbody>
          </table>
        </card>
        <card title="Upload Content">
          <p>
            Upload a new version of the content. After the uploading and
            processing has finished it will be ready to be downloaded by the
            MagicINFO players.
          </p>
          <p>
            To configure this content group for a player give it this Wherever tag:
            <span class="ti-tag example-tag">Content Group: {{playerContent}}</span>
          </p>
          <p>
            Please stay on this page while uploading.
          </p>
          <div v-if="upload.isUploading === true">
            <div class="up-loader clearfix">
              <img :src="loadingImage" class="left" />
              <span class="left upload-status">
                {{ upload.status }}
                <span v-if="upload.status.indexOf('Uploading') > -1">
                  {{ upload.progress }}%
                  <span v-if="this.upload.progressSpeed" class="upload-speed">
                    ({{ this.upload.progressSpeed }})</span>
                </span>
              </span>
            </div>
          </div>
          <div v-else>
            <property title="Content Item">
              <input type="file" ref="contentItemFile" />
            </property>
            <base-button
              type="THEMED"
              iconType="UPLOAD"
              v-on:click="handleContentItemUpload"
              title="Upload file"
            />
          </div>
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import Property from '../Util/Property.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'single-miplayer-content',
  extends: BaseComponent,
  components: {
    Card,
    TextProperty,
    Grid,
    GridCol,
    Property,
    BaseButton,
  },
  props: ['playerContent'],
  watch: {
    playerContent(newVal) {
      if (newVal) {
        this.getPlayerContentVersions(newVal);
        this.getLatestPlayerContent(newVal);
      }
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      contentVersions: [],
      latestPlayerContent: null,
      upload: {
        isUploading: false,
        status: '',
        interval: null,
        progress: 0,
        progressSpeedList: [],
      },
    };
  },
  methods: {
    async getPlayerContentVersions(contentUnique) {
      if (!contentUnique) {
        return;
      }

      try {
        const response = await this.$utils.http().get(`/api/v1/miplayer-content/groups/${contentUnique}`);
        this.ajaxCompleted = true;
        this.contentVersions = response.data;
      } catch (err) {
        this.ajaxCompleted = true;
        this.$noty.warning(`Could not load the player content: ${err.response.data.message}`);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async handleGetStatusUpdates(contentItemId) {
      this.upload.interval = setInterval(async () => {
        try {
          const response = await this.$utils.http().get(`/api/v1/miplayer-content/upload-status/${contentItemId}`);
          if (response.data.status === 'deployed') {
            this.getLatestPlayerContent(this.playerContent);
            this.getPlayerContentVersions(this.playerContent);

            clearInterval(this.upload.interval);
            this.upload.isUploading = false;
            this.$root.$emit('reloadTable');
          }

          this.upload.status = `${this.capitalizeFirstLetter(response.data.status)}...`;
        } catch (err) {
          this.$noty.warning(`Could not load the player content: ${err.response.data.message}`);
          clearInterval(this.upload.interval);
        }
      }, 1000);
    },
    async getLatestPlayerContent(contentUnique) {
      if (!contentUnique) {
        return;
      }

      try {
        const response = await this.$utils.http().get(`/api/v1/miplayer-content/latest/${contentUnique}`);
        this.ajaxCompleted = true;
        this.latestPlayerContent = response.data;
      } catch (err) {
        this.ajaxCompleted = true;
        if (err.response.status === 404) {
          this.latestPlayerContent = null;
        } else {
          this.$noty.warning(`Could not load the player content: ${err.response.data.message}`);
        }
      }
    },
    bytesToSize(bytes) {
      if (!Number.isNaN(bytes)) {
        // Disabled for now, shouldn't be needed anymore when Typescript is implement here
        // eslint-disable-next-line no-param-reassign
        bytes = parseInt(bytes, 10);
      } else {
        return '-';
      }

      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024), 10), 10);
      if (i !== 0) {
        return `${Math.round((bytes / 1000 ** i) * 100) / 100} ${sizes[i]}`;
      }
      return `${Math.round(bytes / 1000 ** i)} ${sizes[i]}`;
    },
    async handleContentItemUpload() {
      if (this.$refs.contentItemFile.files.length > 0) {
        const contentItem = this.$refs.contentItemFile.files[0];
        const contentItemType = contentItem.type;
        if (!contentItemType === 'application/zip') {
          this.$noty.error('Only ZIP-archives can be uploaded as content items');
          return;
        }

        const formData = new FormData();
        formData.append('playerContent', contentItem);
        try {
          this.upload = {
            isUploading: true,
            status: 'Uploading...',
            progressSpeedList: [],
          };
          const response = await this.$utils.http().post(`/api/v1/miplayer-content/upload/${this.playerContent}`, formData, {
            timeout: -1,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.$set(this.upload, 'progress', progress);
              const now = Date.now();

              if (this.upload.progressSpeedList.length === 10) {
                this.upload.progressSpeedList.shift();
              }

              this.upload.progressSpeedList.push({
                timestamp: Date.now(),
                uploaded: progressEvent.loaded,
              });

              if (this.upload.progressSpeedList.length === 10) {
                const deltaTime = now - this.upload.progressSpeedList[0].timestamp;
                const deltaProgress = progressEvent.loaded -
                this.upload.progressSpeedList[0].uploaded;

                const bytesPerSecond = deltaProgress / (deltaTime / 1000);
                this.upload.progressSpeed = `${this.bytesToSize(bytesPerSecond)}/s`;
              }
            },
          });

          this.handleGetStatusUpdates(response.data.contentItemId);
        } catch (e) {
          this.upload = {
            isUploading: false,
            status: `Upload failed: ${e}`,
          };
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.upload.interval);
  },
  mounted() {
    this.getPlayerContentVersions(this.playerContent);
    this.getLatestPlayerContent(this.playerContent);
  },
};
</script>

<style lang="scss" scoped>
.content-version-table {
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);

  tr th {
    text-align: left;
  }

  tr td, tr th {
    padding: 4px 5px;
  }

  tr:not(:last-child) td, tr:not(:last-child) th {
      border-bottom: 1px solid rgba(0,0,0,0.1);
  }
}
.up-loader {
  img {
    width: 30px;
  }
}
.upload-status {
  line-height: 30px;
  padding-left: 10px;
}
.upload-speed {
  opacity: 0.6;
  display: inline-block;
  margin-left: 6px;
}
.example-tag {
  font-size: 11px;
}
</style>
